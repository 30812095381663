import { Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-child-topnav',
  templateUrl: './modal-child-topnav.component.html',
  styleUrls: ['./modal-child-topnav.component.scss']
})
export class ModalChildTopnavComponent{

  @ViewChild('childModal') childModal: ModalDirective;

  showChildModal(): void {
    this.childModal.show();
  }

  hideChildModal(): void {
    this.childModal.hide();
  }
}
