import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { from } from 'rxjs';
import { Auth } from 'aws-amplify';

import { getUserRole, setUserRole } from 'src/app/utils/util';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { CognitoUser } from '@aws-amplify/auth';

import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import Axios, { AxiosRequestConfig } from 'axios';
import { UserRole } from './auth.roles';

export interface ISignInCredentials {
  email: string;
  password: string;
}

export interface ICreateCredentials {
  email: string;
  password: string;
  displayName: string;
  lastName: string;
}

export interface IActiveAccound{
  email: string;
  codeActivation: string;
}

export interface IPasswordReset {
  code: string;
  newPassword: string;
  email: string;
}

export interface UserInfo {
  user_profiles_id: string;
  cpl_role_id: number;
  name: string;
  last_name: string;
  email: string;
  photo?: string;
  status: string;
  zoho_client_id?: number;
  created_at: Date;
  updated_at: Date;
}

@Injectable({ providedIn: 'root' })
export class AuthService {

  public userInfo?: UserInfo;

  constructor(private auth: AngularFireAuth, private http: HttpClient) {
    this.userInfo = null;
  }

  // tslint:disable-next-line:typedef
  signIn(credentials: ISignInCredentials) {
    return Auth.signIn({ username: credentials.email, password: credentials.password }).then(() => {
      return this.getUser()
    });
    // return this.auth
    //   .signInWithEmailAndPassword(credentials.email, credentials.password)
    //   .then(({ user }) => {
    //     return user;
    //   });
  }

  async getInfoUser(email: string){
    const url = environment.apiService + "/user/info"
    const body = {
      email
    }

    if(this.userInfo != null) {
      return this.userInfo;
    }

    const config: AxiosRequestConfig = {
      headers: { 'Authorization': await this.getTokenCognito() }
    }

    const { data } = await Axios.post(url, body, config )
    const { body: bodyResponse } = data;
    if(bodyResponse.length > 0) {
      this.userInfo = bodyResponse[0];
    }
    return this.userInfo;
  }

  // tslint:disable-next-line:typedef
  signOut() {
    // remove information in localstorage
    // Change role to default
    return Auth.signOut().then(res => {
      localStorage.clear()
      this.userInfo = null;
      return res;
    })
    // return from(this.auth.signOut());
  }

  // tslint:disable-next-line:typedef
  register(credentials: ICreateCredentials) {
    return Auth.signUp({
      username: credentials.email,
      password: credentials.password,
      attributes: {
        name: credentials.displayName,
        email: credentials.email,
      }
    });
  }

  registerDB(credentials: ICreateCredentials){
    const url = environment.apiService + "/user"
    const body = {
      name: credentials.displayName,
      lastname: credentials.lastName,
      email: credentials.email
    }
    return this.http.post(url, body, { headers: {} })
    .pipe(
      map((res: any) => {
        return res;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }

  activeCognitoAccount(credentials: IActiveAccound){
    return Auth.confirmSignUp(credentials.email, credentials.codeActivation, { forceAliasCreation: true })
  }

  activeDBUser(email: string){
    const url = environment.apiService + "/user/active"
    const body = {
      email
    }
    return this.http.post(url, body, { headers: {} })
    .pipe(
      map((res: any) => {
        return res;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }

  // tslint:disable-next-line:typedef
  sendPasswordEmail(email) {
    return Auth.forgotPassword(email).then(() => {
      return true
    })
    // return this.auth.sendPasswordResetEmail(email).then(() => {
    //   return true;
    // });
  }

  // tslint:disable-next-line:typedef
  resetPassword(credentials: IPasswordReset) {
    return Auth.forgotPasswordSubmit(credentials.email, credentials.code, credentials.newPassword)
    // return this.auth
    //   .confirmPasswordReset(credentials.code, credentials.newPassword)
    //   .then((data) => {
    //     return data;
    //   });
  }


  async getTokenCognito() {
    const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser().then((result: CognitoUser) => result).catch(() => undefined);

    if(!cognitoUser){
      return undefined
    }

    const userSession = cognitoUser.getSignInUserSession()

    let token = null
    if(!userSession.isValid){
      token = userSession.getRefreshToken().getToken()
    }

    token = userSession.getIdToken().getJwtToken()
    return token;
  }

  // tslint:disable-next-line:typedef
  async getUser() {
    // Usuario de cognito
    const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser().then((result: CognitoUser) => result).catch(() => undefined);
    
    if(!cognitoUser){
      return undefined;
    }
    
    const { email, email_verified } = cognitoUser.getSignInUserSession().getIdToken().payload;
    const user: UserInfo = await this.getInfoUser(email).catch(() => null)

    if(!user){
      return undefined;
    }

    let role = this.getRoleDatabase(user.cpl_role_id);

    setUserRole(role);

    return { ...user, role: getUserRole(), email_verified, displayName: `${user.name} ${user.last_name}` };
  }

  getRoleDatabase(role){
    switch (role) {
      case 1:
        return UserRole.User
      case 2:
        return UserRole.Admin
      case 3:
        return UserRole.AdminClient
      case 4:
        return UserRole.ContentManager
      default:
        return UserRole.Default
    }
  }
}
