<div class="modal-header">
    <h4 class="modal-title pull-left dm-serif-display">{{title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12">
            <form [formGroup]="formCrear" class="tooltip-center-top">
                <div class="input-group">
                    <textarea class="form-control" placeholder="Haz una pregunta..." formControlName="post" name="post" rows="5" style="border: 0; resize: none; outline: none; overflow:hidden;"></textarea>
                </div>
            </form>
        </div> 
    </div>    
</div>
<div class="modal-footer">
    <!--button type="button" class = "btn btn-primary btn-block mb-1"  (click)="submit()" [disabled]="!formCrear.valid">Enviar</button-->
    <button [class]="currentState +  ' btn btn-multiple-state btn-primary btn-block mb-1'" [disabled]="!formCrear.valid" triggers="" (click)="submit()">
    <div class="spinner d-inline-block">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
    </div>
    <span class="label source-sans-pro">
        Enviar
        <ng-content></ng-content>
    </span>    
    </button>
</div>