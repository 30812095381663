import { environment } from 'src/environments/environment';
import { UserRole } from '../shared/auth.roles';
const adminRoot = environment.adminRoot;

export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
  roles?: UserRole[];
}

const data: IMenuItem[] = [
  {
    icon: 'iconsminds-home',
    label: 'menu.app',
    to: `${adminRoot}/home`,
    roles: [UserRole.User, UserRole.Admin, UserRole.AdminClient, UserRole.ContentManager],
    // subs: [
    //   {
    //     icon: 'simple-icon-paper-plane',
    //     label: 'menu.start',
    //     to: `${adminRoot}/vien/start`,
    //     roles: [UserRole.Admin],
    //   },
    // ],
  },
  {
    icon: 'iconsminds-megaphone',
    label: 'menu.community',
    to: `${adminRoot}/community`,
    roles: [UserRole.User, UserRole.Admin, UserRole.AdminClient, UserRole.ContentManager],
    // subs: [
    //   {
    //     icon: 'simple-icon-paper-plane',
    //     label: 'menu.second',
    //     to: `${adminRoot}/second-menu/second`,
    //   },
    // ],
  },
  {
    icon: 'iconsminds-support',
    label: 'menu.help',
    to: `${adminRoot}/help`,
    roles: [UserRole.User, UserRole.Admin, UserRole.AdminClient],
  },
  {
    icon: 'iconsminds-bucket',
    label: 'menu.my-admin',
    to: `${adminRoot}/my-admin`,
    roles: [UserRole.AdminClient],
  },
  {
    icon: 'iconsminds-network',
    label: 'menu.users',
    to: `${adminRoot}/users`,
    roles: [UserRole.Admin],
  },
  // {
  //   icon: 'iconsminds-bucket',
  //   label: 'menu.blank-page',
  //   to: `${adminRoot}/blank-page`,
  //   roles: [UserRole.Admin],
  // },
];
export default data;
