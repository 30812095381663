import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../shared/auth.service';
import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { UserRole } from '../shared/auth.roles';

export interface IProduct {
  id: number;
  title: string;
  img: string;
  category: string;
  status: string;
  statusColor: string;
  description: string;
  sales: number;
  stock: number;
  date: string;
}

export interface IProductResponse {
  data: IProduct[];
  status: boolean;
  totalItem: number;
  totalPage: number;
  pageSize: string;
  currentPage: string;
}

export interface ISolicitudes {
  id: string;
  asunto: string;
  folio: string;
  prioridad: string;
  requerimiento: string;
  resposable: string;
  status: string;
  creation: string;
  usuario: string;
  due_date: string;
}

export interface ILeaderBoards {
  comentarios: string;
  id: string;
  likes: string;
  userName: string;
}

export interface IPosts {
  id: string;
  title: string;
  comments: ICommentsPosts[];
  userName: string;
  creationdate: string;
  tag: string;
  content: string;
  preview: string;
  short_intro: string;
  email_usr: string;
}

export interface ICommentsPosts {
  comment: string;
  ID: string;
  username: string;
}

export interface IArticles {
  preview: string;
  featured: string;
  short_intro: string;
  content_url: string;
  ID: string;
  tag: string;
  title: string;
  Added_Time: string;
  username: string;
}

export interface IUser {
  role: UserRole;
  createdAt: string;
  email: string;
  lastName: string;
  name: string;
  photo: string;
  status: string;
  id: string;
  zohoId: number;
}

export interface IUserInfo {
  zohoId: number;
  contTicket: number;
}

export interface IChatTicket {
  conversation: string;
  role: string;
  createdAt: string;
  email: string;
  aproved: string;
}

@Injectable({ providedIn: 'root' })
export class ApiService {
  constructor(private http: HttpClient, private authService: AuthService) { }

  getProducts(pageSize: number = 10, currentPage: number = 1, search: string = '', orderBy: string = '') {
    const url = environment.apiUrl + '/cakes/paging';
    let params = new HttpParams();
    params = params.append('pageSize', pageSize + '');
    params = params.append('currentPage', currentPage + '');
    params = params.append('search', search);
    params = params.append('orderBy', orderBy);

    return this.http.get(url, { params })
      .pipe(
        map((res: IProductResponse) => {
          return res;
        }),
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  async getSolicitudes(zoho_id: string): Promise<ISolicitudes[]> {
    const url = environment.apiService + "/solicitudes/get-solicitudes"
    const config: AxiosRequestConfig = {
      headers: { 'Authorization': await this.authService.getTokenCognito() }
    }
    const body = {
      id: zoho_id
    }
    const response = await Axios.post(url, body, config ).then((res: AxiosResponse) => {
      const { statusCode , body } = res.data;
      if (statusCode !== 200) {
        return []
      }
      return body.data.map(d => (<ISolicitudes>{ ...d, id: d.ID, creation: d.Added_Time, usuario: d.cliente_cpl, due_date: d.due_date_cpl }))

    })
    return response;
  }

  async creatSolicitud(asunto: string, requerimiento: string, prioridad: string, clientId: string, email: string, due_date: string) {
    const url = environment.apiService + "/solicitudes"
    const config: AxiosRequestConfig = {
      headers: { 'Authorization': await this.authService.getTokenCognito() }
    }

    const body = {
      asunto,
      prioridad,
      requerimiento,
      clientId,
      email,
      due_date
    }

    const response = await Axios.post(url, body, config);
    //console.log(response['data']['body']['data']['ID'])
    return response;
  }

  async cancelSolicitud(id: string){
    const url = environment.apiService + "/solicitudes/cancel"
    const config: AxiosRequestConfig = {
      headers: { 'Authorization': await this.authService.getTokenCognito() }
    }

    const body = {
      id_ticket: id
    }

    const response = await Axios.post(url, body, config);
    return response;
  }

  async getLeaderBoards(): Promise<ILeaderBoards[]>{
    const url = environment.apiService + "/posts/leaders"
    const config: AxiosRequestConfig = {
      headers: { 'Authorization': await this.authService.getTokenCognito() }
    }

    const response = await Axios.get(url, config ).then((res: AxiosResponse) => {
      const { statusCode , body } = res.data;
      if (statusCode !== 200) {
        return []
      }

      return body.data.map(d => (<ILeaderBoards>{ id: d.ID, comentarios: d.cantidad_de_comentarios, likes: d.cantidad_de_likes, userName: d.username }))

    })
    return response;
  }

  async getPosts(): Promise<IPosts[]>{
    const url = environment.apiService + "/posts"
    const config: AxiosRequestConfig = {
      headers: { 'Authorization': await this.authService.getTokenCognito() }
    }

    const response = await Axios.get(url, config ).then((res: AxiosResponse) => {
      const { statusCode , body } = res.data;
      if (statusCode !== 200) {
        return []
      }
      //console.log(body.data)
      const arrayRes: IPosts[] = []

      //const cleanRes = this.groupBy(body.data, 'post.ID')
      for (const [key, value ] of Object.entries(body.data)) {
        //console.log(value['username'])
        arrayRes.push(
          <IPosts>{
            id: key,
            title: value['title'],
            userName: value['username'],
            creationdate: value['Added_Time'],
            short_intro: value['short_intro'],
            preview: value['preview'],
            tag: value['tag'],
            content: value['content_url'],
            comments: value['Comments'],
            username: value['username'],
            idCreator: value['ID'],
            email_usr: value['correo_usr'],
            }
          )
      }
      //console.log(cleanRes)
      return arrayRes

    })
    return response;
  }

  groupBy(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  async getArticles(): Promise<IArticles[]>{
    const url = environment.apiService + "/articles"
    const config: AxiosRequestConfig = {
      headers: { 'Authorization': await this.authService.getTokenCognito() }
    }

    const response = await Axios.get(url, config ).then((res: AxiosResponse) => {
      const { statusCode , body } = res.data;
      if (statusCode !== 200) {
        return []
      }
      
      return body.data.map(d => (<IArticles>{...d}))

    })
    return response;
  }

  async createPost(postDescription: string, userName: string, correo_usr: string): Promise<void> {
    const url = environment.apiService + "/posts"
    const config: AxiosRequestConfig = {
      headers: { 'Authorization': await this.authService.getTokenCognito() }
    }

    const body = {
      shortIntro: postDescription,
      contentUrl: "",
      userName,
      correo_usr
    }

    await Axios.post(url, body, config);
  }

  async createCommentPost(id: string, comment: string, userName: string): Promise<void> {
    const url = environment.apiService + "/posts/comment"
    const config: AxiosRequestConfig = {
      headers: { 'Authorization': await this.authService.getTokenCognito() }
    }

    const body = {
      postId: id,
      comment,
      userName
    }

    await Axios.post(url, body, config);
  }

  async getUserList(): Promise<IUser[]> {
    const url = environment.apiService + "/user"
    const config: AxiosRequestConfig = {
      headers: { 'Authorization': await this.authService.getTokenCognito() }
    }

    const response = await Axios.get(url, config ).then((res: AxiosResponse) => {
      const { statusCode, body } = res.data;
      if (statusCode !== 200) {
        return []
      }

      return body.map(u => (<IUser>{
        ...u,
        role: this.getRoleDatabase(u.cpl_role_id),
        createdAt: u.created_at,
        lastName: u.last_name,
        id: u.user_profiles_id,
        zohoId: u.zoho_client_id
      }))
    })
    return response;
  }

  getRoleDatabase(role){
    switch (role) {
      case 1:
        return UserRole.User
      case 2:
        return UserRole.Admin
      case 3:
        return UserRole.AdminClient
      case 4:
        return UserRole.ContentManager
      default:
        return UserRole.Default
    }
  }

  getRoleClient(role: UserRole) {
    switch (role) {
      case UserRole.User:
        return 1
      case UserRole.Admin:
        return 2
      case UserRole.AdminClient:
        return 3
      case UserRole.ContentManager:
        return 4
      default:
        return 0
    }
  }

  async updateUser(id: string, clientId: number, role: UserRole, user: IUser): Promise<void> {
    const url = environment.apiService + "/user"
    const config: AxiosRequestConfig = {
      headers: { 'Authorization': await this.authService.getTokenCognito() }
    }

    const body = {
      roleId: this.getRoleClient(role),
      name: user.name,
      lastName: user.lastName,
      zohoId: clientId,
      id
    }

    await Axios.patch(url, body, config);
  }

  uploadFile(name: string, file_type: string){
    console.log(name + file_type)
  }

  async getUserInfoCreator(email: string): Promise<IUserInfo[]>{
    const url = environment.apiService + "/user/infocreator"

    const body = {
      email
    }

    const response = await Axios.post(url, body).then((res: AxiosResponse) => {
      const { statusCode , body } = res.data;
      if (statusCode !== 200) {
        return []
      }
      return body.data.map(d => (<IUserInfo>{ ...d, zohoId: d.zoho_client_id, contTicket: d.contador_usuarios }))

    })
    return response;
  }

  async deletePost(id: string){
    const url = environment.apiService + "/posts/cancel"
    const config: AxiosRequestConfig = {
      headers: { 'Authorization': await this.authService.getTokenCognito() }
    }

    const body = {
      id_post: id
    }

    const response = await Axios.post(url, body, config);
    return response;
  }

  async updatePost(title: string, id_post: string){
    const url = environment.apiService + "/posts/update"
    const config: AxiosRequestConfig = {
      headers: { 'Authorization': await this.authService.getTokenCognito() }
    }

    const body = {
      id_post,
      title
    }

    const response = await Axios.post(url, body, config);
    return response;
  }

  async getChatTicket(id_ticket: string): Promise<IChatTicket[]> {
    const url = environment.apiService + "/solicitudes/getchat"
    const config: AxiosRequestConfig = {
      headers: { 'Authorization': await this.authService.getTokenCognito() }
    }
    const body = {
      id_ticket
    }
    const response = await Axios.post(url, body, config ).then((res: AxiosResponse) => {
      const { statusCode , body } = res.data;
      if (statusCode !== 200 || body.code == 3100) {
        return []
      }
      return body.data.map(d => (<IChatTicket>{ ...d, conversation: d.texto, role: d.tipo_persona, createdAt: d.Added_Time, email: d.remitente, aproved: d.aprobacion }))

    })
    return response;
  }

  async createChat(email: string, texto: string, id_ticket: string){
    const url = environment.apiService + "/solicitudes/createchat"
    const config: AxiosRequestConfig = {
      headers: { 'Authorization': await this.authService.getTokenCognito() }
    }

    const body = {
      email,
      id_ticket,
      texto
    }

    const response = await Axios.post(url, body, config);
    return response;
  }

 async updateStatusTicket(id_ticket: string, email: string, statusticket: string)  {
  const url = environment.apiService + "/solicitudes/updatestatus"
  const config: AxiosRequestConfig = {
    headers: { 'Authorization': await this.authService.getTokenCognito() }
  }

  const body = {
    email,
    id_ticket,
    statusticket
  }

  const response = await Axios.post(url, body, config);
  return response;
 }
}
