import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/data/api.service';
import { AuthService } from 'src/app/shared/auth.service';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { Router } from '@angular/router';


@Component({
  selector: 'app-modal-inner',
  templateUrl: './modal-inner-components.html'
})

export class ModalInnerComponent implements OnInit {
  title: string;
  closeBtnName: string;
  list: any[] = [];
  formCrear: FormGroup;
  userInfo = null;
  currentState = '';

  constructor(public bsModalRef: BsModalRef, private authService: AuthService, private apiService: ApiService, private notifications: NotificationsService, private router: Router) { }

  async ngOnInit(): Promise<void> {
    this.formCrear = new FormGroup({
      post: new FormControl(this.post, [
          Validators.required
      ]),
    });
    this.userInfo = await this.authService.getUser();
  }

  get post(){ return this.formCrear?.get('post') }

  async submit() {
    this.currentState = 'show-spinner';
    this.apiService.createPost(this.post.value , this.userInfo.displayName, localStorage.getItem('email')).then(() => {
      this.notifications.create('Listo', 'Post creado correctamente',
        NotificationType.Success, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
      this.formCrear.reset();
      this.router.navigate(['app/community']);
    }).catch(error => {
      this.notifications.create('Error', error.message,
        NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
    })
    this.bsModalRef.hide();
  }
}
