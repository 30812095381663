<button type="button" class="btn btn-primary" (click)="showChildModal()">{{'modal.open-modal' | translate}}</button>
<div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1"
     role="dialog" aria-labelledby="dialog-child-name">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-child-name" class="modal-title pull-left">{{'modal.modal-title' | translate}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideChildModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!--div class="modal-body">
        ...
      </div-->
    </div>
  </div>
</div>

